import styled from 'styled-components';

export const StyledRadioGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRadioGroup = styled.div<{ optionType?: string }>`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.optionType === 'button' ? 'row' : 'column')};
`;

export const StyleBtnLabel = styled.label<{disabled: boolean}>`
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;
  font-size: 12px;
  
  input {
    display: none;
    ~ .btnType{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      margin-bottom: 10px;
      border-radius: 4px;
      padding: 0 12px;
      min-width: 95px;
      height: 32px;
      line-height: 11px;
      border: 1px solid #BBB;
      margin: auto;
      color: ${(props) => props.theme.radio.primary};
      font-size: 16px;
      font-weight: 400;
    }
    :checked ~ .btnType {
      border: 1px solid #013B81;
      color: #013B81;
    }
    :disabled ~ .btnType{
      background-color: #FFFFFF;
      border: 1px solid #BBBBBB;
    }
    :disabled:checked {
      ~ .btnType{
        background: #FCFCFC;
        border: 1px solid rgba(20, 93, 181, 0.5);
        color: rgba(20, 93, 181, 0.5);
      }
    }
  }
`;

export const StyledNormalRadio = styled.div`
  display: flex;
  align-items: flex-start;

  >label{
    color: #5C5C5C;
    cursor: pointer;
    font-size: 14px;
    flex: 1;
  }
  input {
    cursor: pointer;
    margin-right: 10px;
    margin-left: 0;
    width: 15px;
    height: 15px;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

import { generateState, generateCodeChallenge, generateCodeVerifier } from 'utils/auth';
import { Languages } from 'constant/language';
import { transferLangIntoCmp } from 'utils/common';

const redirectToKeycloakLogin = (): void => {
  const urlParams = new URLSearchParams(window.location.search);

  const transferLangIntoKeycloak = (lang: string | null): string => {
    if (!lang) return 'en';
    if (lang === 'zh') return 'cn';
    if (lang === 'zh_TW') return 'tw';
    return lang;
  };

  const redirectToKeycloakWithAuthCode = (pkceState: string, codeChallenge: string): void => {
    let authorizationUrl = `${process.env.REACT_APP_OIC_ENDPOINT}/auth`;
    authorizationUrl += '?client_id=meetup-app-pkce-client';
    authorizationUrl += '&response_type=code';
    authorizationUrl += '&scope=openid%20profile';
    authorizationUrl += `&redirect_uri=${process.env.REACT_APP_ROOT_URL}${process.env.REACT_APP_CALLBACK_PATH}${window.location.pathname}`;
    authorizationUrl += `&state=${pkceState}`;
    authorizationUrl += `&registerUrl=${window.location.origin}/open-live-account`;
    authorizationUrl += `&code_challenge=${codeChallenge}`;
    authorizationUrl += '&code_challenge_method=S256';

    // Choose the language to use in Keycloak login page.
    if (urlParams.get('locale')) {
      authorizationUrl += `&ui_locales=${transferLangIntoKeycloak(urlParams.get('locale'))}`;
      urlParams.delete('locale');
    } else if (localStorage.getItem('language')) {
      authorizationUrl += `&ui_locales=${transferLangIntoKeycloak(localStorage.getItem('language'))}`;
    } else {
      const lowerCaseBrowserLangList = window.navigator.languages;
      let lowerCaseBrowserLang = '';
      const languageArr = [...Object.values(Languages), 'zh-cn', 'zh-tw'] as string[];
      lowerCaseBrowserLangList.some((eachLang) => {
        lowerCaseBrowserLang = eachLang.toLocaleLowerCase();
        return languageArr.includes(eachLang.toLocaleLowerCase());
      });
      if (lowerCaseBrowserLang.includes('-') && lowerCaseBrowserLang !== 'zh-tw' && lowerCaseBrowserLang !== 'zh-cn') {
        [lowerCaseBrowserLang] = lowerCaseBrowserLang.split('-');
      }
      const browserLang = transferLangIntoCmp(lowerCaseBrowserLang);
      localStorage.setItem('language', browserLang);
      authorizationUrl += `&ui_locales=${transferLangIntoKeycloak(localStorage.getItem('language'))}`;
    }

    window.location.href = authorizationUrl;
  };

  const codeVerifier = generateCodeVerifier();
  localStorage.setItem('codeVerifier', codeVerifier);
  redirectToKeycloakWithAuthCode(generateState(), generateCodeChallenge(codeVerifier));
};

export default redirectToKeycloakLogin;

/* eslint-disable react/jsx-no-useless-fragment */
import { useTranslation } from 'react-i18next';
import { StyledInfo } from './style';

interface InfoProps {
  value: string,
  type: string,
  marginTop?: string
}

interface InfoMapping {
  [key: string]: {
    [key: string]: string,
  }
}

enum Types {
  tradingPlatefrom = 'trading_platform',
  leverage= 'leverage',
  mt4 = 'MetaTrader4',
  mt5 = 'MetaTrader5',
  logixTrader = 'LogixTrader',
  dynamicMargin = 'Dynamic Margin',
}

const Info = ({
  value,
  type,
  marginTop
}: InfoProps): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const infoMapping: InfoMapping = {
    [Types.tradingPlatefrom]: {
      [Types.mt4]: t('MetaTradingPlateformInfo'),
      [Types.mt5]: t('MetaTradingPlateformInfo'),
      [Types.logixTrader]: t('LogixTraderPlateformInfo')
    },
    [Types.leverage]: {
      [Types.dynamicMargin]: t('DynamicMarginLeverageInfor')
    }
  };

  return (
    <>
      {infoMapping[type] && infoMapping[type][value] && (<StyledInfo marginTop={marginTop}>{infoMapping[type][value]}</StyledInfo>)}
    </>
  );
};

export default Info;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Icon from 'components/Icons';
import { StyledEmailVerficationTitle } from 'containers/RegisterFormContainers/style';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { sendEmailVerificationCode, sendMobileVerificationCode, userRegister } from 'api/v1/user';
import ErrorHandler from 'utils/ErrorHandler';
import { useAppDispatch } from 'store';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useFormContext } from 'react-hook-form';
import axios from 'api/axios';
import BackdropSpinner from 'components/BackdropSpinner';
import { setGlobalPopUp } from 'containers/Pages/slices';
import { IFormInput } from 'containers/RegisterFormContainers/CheckEmailForm';
import { handleSignupPageUrl } from 'utils/common';
import { jwtDecode } from 'utils/auth';
import {
  StyledEmailVerificationWrap,
  StyledDescription,
  StyledVerificationInputWrap,
  StyledResendWrap,
  StyledResend,
  StyledButtonWrap,
  StyledBackWrap,
  StyledBack,
  StyledErrorMessage
} from './style';

interface VerifyCodeProps {
  handleBack: () => void;
  handlePopupErrMessage: (errMessage: string) => void;
  mobileCountryCode: string
}

const VerifyCode = (props: VerifyCodeProps):JSX.Element => {
  const { t } = useTranslation('createAccount');
  // const [isResendReady, setIsResendReady] = useState(true);
  const [isResendReady, setIsResendReady] = useState(false);
  const [remainTime, setRemainTime] = useState(0);
  const [verificationCode, setVerificationCode] = useState('');
  const [isError, setIsError] = useState(false);
  const [verificationType, setVerificationType] = useState<'email' | 'mobile'>('email');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const timerRef: { current: NodeJS.Timeout | null } = useRef(null);
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const { handleBack, handlePopupErrMessage, mobileCountryCode } = props;
  const { getValues } = useFormContext();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const realm = process.env.REACT_APP_REALM;

  const handleResendEmail = (): void => {
    if (inputRef.current) inputRef.current.value = '';
    if (isResendReady) {
      const data = getValues() as IFormInput;
      setIsResendReady(false);
      const userInfo = { email: data.email, firstName: data.firstName, lastName: data.lastName };
      ErrorHandler(sendEmailVerificationCode(userInfo), dispatch);
    }
  };

  const handleResendSMS = (): void => {
    if (inputRef.current) inputRef.current.value = '';
    if (isResendReady) {
      const data = getValues() as IFormInput;
      setIsResendReady(false);
      const mobileNumber = data.mobile ? data.mobile.slice(mobileCountryCode.length) : '';
      const mobileInfo = { mobileCountryCode, mobileNumber };
      sendMobileVerificationCode(mobileInfo).catch((err) => {
        dispatch(setGlobalPopUp({ isModalOpen: true, msg: err.response?.data?.message }));
      });
    }
  };

  const handleVerify = (): void => {
    const data = getValues() as IFormInput;
    const lastViewUrl = searchParams.get('url') || '';
    const mobileNumber = data.mobile ? data.mobile.slice(mobileCountryCode.length) : '';
    const registerData = {
      verificationCode,
      signupPageUrl: handleSignupPageUrl(),
      lastViewUrl,
      demoCookieUrl: window.location.origin,
      mobileNumber,
      mobileCountryCode,
      verificationType,
      ...data
    };

    setIsLoading(true);

    userRegister(registerData).then((response) => {
      if (response && response.data && response.data.access_token) {
        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
        localStorage.setItem('registeredCountry', registerData.country);
        localStorage.setItem('firstName', registerData.firstName);
        const decodedObj = jwtDecode(response.data.access_token);
        localStorage.setItem('uid', decodedObj.sub);

        window.location.href = searchParams.get('next') || '/';
      }
    }).catch((err) => {
      if (err.response.status === 401) {
        setIsError(true);
      } else {
        handlePopupErrMessage(err.response.data.message);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleResetTimer = (): void => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  const handleVerificationType = (type: 'email' | 'mobile'): void => {
    if (type === 'email') handleResendEmail();
    if (type === 'mobile')handleResendSMS();
    setVerificationType(type);
    setIsError(false);
    setIsResendReady(false);
    AuthInputRef.current?.clear();
  };

  useEffect(() => {
    if (!isResendReady) {
      let time = 60;
      setRemainTime(time);
      timerRef.current = setInterval(() => {
        time -= 1;
        if (time === 0) {
          setIsResendReady(true);
        }
        setRemainTime(time);
      }, 1000);
    }
    return () => handleResetTimer();
  }, [isResendReady]);

  return (
    <div>
      <StyledEmailVerificationWrap>
        <StyledEmailVerficationTitle aria-label={verificationType === 'email' ? 'email verification' : 'mobile verification'}>
          {verificationType === 'email' ? t('emailVerification') : t('mobileVerification')}
          <StyledDescription
            aria-label={
              verificationType === 'email' ? 'Enter the security code sent to your email' : 'Enter the security code sent to your phone number'
            }
          >
            {verificationType === 'email' ? t('securityCode') : t('mobileSecurityCode')}
          </StyledDescription>
        </StyledEmailVerficationTitle>
        <StyledVerificationInputWrap isError={isError}>
          <AuthCode
            autoFocus
            ref={AuthInputRef}
            allowedCharacters="numeric"
            containerClassName="container"
            inputClassName="input"
            length={4}
            onChange={(code) => setVerificationCode(code)}
          />
        </StyledVerificationInputWrap>
        {isError && (
        <StyledErrorMessage>
          <Icon name="Error" />
          {t('invalidError')}
        </StyledErrorMessage>
        )}
        <StyledButtonWrap>
          <Button
            aria-label="verify code button"
            type="submit"
            onClick={handleVerify}
            disabled={!!(verificationCode.length !== 4)}
            className={realm === 'ubank' ? 'ubank-custom-button' : 'custom-button'}
          >
            {t('verify')}
          </Button>
        </StyledButtonWrap>
        <StyledResendWrap>
          <span aria-label="resend code in 60 sec">{!isResendReady && `${t('resendOne')} ${remainTime}${t('resendTwo')}`}</span>
          {isResendReady && verificationType === 'email' && (
            <>
              <span aria-label="didn't receive the email">{t('emailSend')}</span>
              &nbsp;
              <StyledResend onClick={handleResendEmail} aria-label="click to resend" ubank={realm === 'ubank'}>
                {t('clickToResend')}
              </StyledResend>
              &nbsp;
              {t('or')}
              &nbsp;
              <StyledResend onClick={() => handleVerificationType('mobile')} aria-label="receive code via phone number" ubank={realm === 'ubank'}>
                {t('receiveCodeViaPhoneNumber')}
              </StyledResend>
            </>
          )}
          {isResendReady && verificationType === 'mobile' && (
            <>
              {t('mobileSend')}
              &nbsp;
              <StyledResend onClick={handleResendSMS} ubank={realm === 'ubank'}>
                {t('clickToResend')}
              </StyledResend>
            </>
          )}
        </StyledResendWrap>
        <StyledBackWrap>
          <StyledBack onClick={handleBack} aria-label="back to create account page">
            <Icon name="ArrowBack" />
            {t('goBack')}
          </StyledBack>
        </StyledBackWrap>
      </StyledEmailVerificationWrap>
      <BackdropSpinner open={isLoading} />
    </div>
  );
};

export default VerifyCode;

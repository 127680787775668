export enum Languages {
  EN = 'en',
  ZH_TW = 'zh_TW',
  ZH_CN = 'zh',
  JP = 'ja',
  KO = 'ko',
  ID = 'id',
  VI = 'vi',
  MS = 'ms',
  ES = 'es',
  FR = 'fr',
  AR = 'ar',
  HE = 'he',
  PT = 'pt'
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme as materialCreateTheme,
  THEME_ID
} from '@mui/material/styles';
import { GlobalStyle } from 'styles/global_css';
// import { io } from 'socket.io-client';
import { connect } from 'stomp';
import { useAppDispatch, useAppSelector } from 'store';
import Button from '@mui/material/Button';
import { setGlobalPopUp } from 'containers/Pages/slices';
import PopUp from 'components/PopUp';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { transferLangIntoCmp } from 'utils/common';
import pJson from '../package.json';
import { lightTheme, primaryTheme } from './styles/theme';
import Router from './Router';

const materialTheme = materialCreateTheme();

const StyledAppContainer = styled.main`
  height: 100vh;
`;

const theme = 'primary';

const StyledContainer = styled.div`
  height: 100vh;
  display: flex;
`;

export const StyledBtnWrap = styled.div`
  > button {
    &.MuiButtonBase-root {
      margin-right: 0;
      text-transform: lowercase;
      font-family: 'Noto Sans';
      min-width: unset;
    }
  }
`;

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const globalPopUp = useAppSelector((state) => state.pageState.globalPopUp);
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n } = useTranslation('createAccount');
  const gtmIframeSrc = (): string => {
    if (process.env.REACT_APP_NODE_ENV === 'dev') { return 'https://www.googletagmanager.com/ns.html?id=GTM-TBGGKFH'; }
    if (process.env.REACT_APP_NODE_ENV === 'prod') { return 'https://www.googletagmanager.com/ns.html?id=GTM-PKSWFJK'; }
    return '';
  };

  useEffect(() => {
    console.info(
      `%c Current Version: ${pJson.version}`,
      'background: #222; color: #bada55'
    );
    const script = document.createElement('script');
    const meta = document.createElement('meta');

    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=cf04c151-2df1-4907-acac-fba7c414e126';
    script.id = 'ze-snippet';
    script.async = true;

    document.body.appendChild(script);
    const gtmScript = document.createElement('script');
    gtmScript.type = 'text/javascript';
    gtmScript.async = true;

    if (process.env.REACT_APP_NODE_ENV === 'dev') {
      gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TBGGKFH');`;
    } else if (process.env.REACT_APP_NODE_ENV === 'prod') {
      gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PKSWFJK');`;

      // Just need facebook code in prod env
      meta.content = 'esot97acbzzdzxuzkaf71uhoc9awjf';
      meta.name = 'facebook-domain-verification';
      document.head.appendChild(meta);
    }
    document.head.appendChild(gtmScript);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(gtmScript);
      document.head.removeChild(meta);
    };
  }, []);

  useEffect(() => {
    // Priority: queryString > customer selected language > browser language
    if (searchParams.get('locale')) {
      const searchParamLocale = transferLangIntoCmp(searchParams.get('locale'));
      localStorage.setItem('language', searchParamLocale);
      i18n.changeLanguage(searchParamLocale);
      searchParams.delete('locale');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    connect(dispatch);
  }, []);
  // useEffect(() => {
  //   const socket = io(ENDPOINT);
  //   socket.on('connect', () => {
  //     console.log('connected');
  //   });
  //   socket.on('disconnect', () => {
  //     console.log('disconnected');
  //   });
  //   // handle other events here
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  return (
    <StyledAppContainer>
      <ThemeProvider theme={theme === 'primary' ? primaryTheme : lightTheme}>
        <MaterialThemeProvider theme={{ [THEME_ID]: materialTheme }}>
          <GlobalStyle
            isRTL={
              localStorage.getItem('language') === 'ar'
              || localStorage.getItem('language') === 'he'
            }
          />
          <StyledContainer>
            <Router />
          </StyledContainer>
          <PopUp
            content={globalPopUp.msg}
            openModal={globalPopUp.isModalOpen}
            onClose={() => dispatch(setGlobalPopUp({ isModalOpen: false, message: '' }))}
          >
            <StyledBtnWrap>
              <Button
                aria-label="Pop Up OK"
                onClick={() => dispatch(setGlobalPopUp({ isModalOpen: false, message: '' }))}
              >
                ok
              </Button>
            </StyledBtnWrap>
          </PopUp>
        </MaterialThemeProvider>
      </ThemeProvider>
      {gtmIframeSrc() && (
        <noscript>
          <iframe
            title="This is for GTM"
            src={gtmIframeSrc()}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
    </StyledAppContainer>
  );
};
export default App;

import axios from 'axios';
import { Languages } from 'constant/language';

const customInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('language') || Languages.EN
  },
  timeout: 60000
});

customInstance.interceptors.request.use(
  (config) => {
    if (config.headers) config.headers['Accept-Language'] = localStorage.getItem('language') || Languages.EN;
    return config;
  },
  (error) => Promise.reject(error)
);
customInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.info(error);
    return Promise.reject(error);
  }
);

export default customInstance;

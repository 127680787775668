/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useTranslation } from 'react-i18next';
import DropDown from 'components/DropDown';
import IconComponent from 'components/Icons';
import { useAppSelector, useAppDispatch } from 'store';
import { toggleSideMenu } from 'containers/SideMenu/slices';
import { Button } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { device } from 'styles/deviceStyle';
import { useNavigate, useLocation } from 'react-router-dom';
import { Languages } from 'constant/language';
import { useAccountRG227State } from 'store/context/accountRG227Context';
import {
  StyledHeaderWrap, StyledSettingWrap, StyledLangIcon,
  StyledItemWrap, StyledHeaderMobileWrap, StyledAcyWrapper, StyledContinueBtnWrap, StyledMobileBtnWrap
} from './style';

const langObj: { [lang: string]: string } = {
  [Languages.EN]: 'English',
  [Languages.ZH_CN]: '中文简体',
  [Languages.ZH_TW]: '中文繁體',
  [Languages.AR]: 'العربية',
  [Languages.ID]: 'Bahasa',
  [Languages.VI]: 'Tiếng Việt',
  [Languages.ES]: 'Española',
  [Languages.KO]: '한국인',
  [Languages.PT]: 'Português',
  [Languages.FR]: 'Français',
  [Languages.JP]: '日本語',
  [Languages.MS]: 'Melayu',
  [Languages.HE]: 'עִברִית'
};

const hasHeaderBtnStatus = ['Incomplete', 'noAccount', 'Reject'];

const Header = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation('common');
  const isMenuOpen = useAppSelector((state) => state.sideMenuState.isOpen);
  const accountStatus = useAppSelector((state) => state.pageState.accountStatus);
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery({ query: device.desktopMinWidth });
  const isMobile = useMediaQuery({ query: device.mobileMaxWidth });
  const createAccountStatus = useAppSelector((state) => state.pageState.createAccountStatus);
  const { exceedFailedAttempts, hasOnceFailed, accountId } = useAccountRG227State();

  const handleIncomplete = ():void => {
    if (createAccountStatus?.isOnBoardingMethodSelecting) {
      navigate('/create-account/verify-identity');
    } else {
      if (createAccountStatus.type === 'corporate') {
        navigate('/create-account/corporate-account');
        return;
      }
      if (createAccountStatus.type === 'joint') {
        navigate('/create-account/joint-account');
        return;
      }
      navigate('/create-account/personal-account');
    }
  };

  const handleReject = ():void => {
    if (createAccountStatus.type === 'corporate') {
      navigate('/create-account/corporate-account?status=Reject');
      return;
    }
    if (createAccountStatus.type === 'joint') {
      navigate('/create-account/joint-account?status=Reject');
      return;
    }
    navigate('/create-account/personal-account?status=Reject');
  };

  const changeLangHandler = (newLang: string): void => {
    localStorage.setItem('language', newLang);
    navigate(0);
  };

  const customLangMenu = Object.keys(langObj).map((item) => (
    <StyledItemWrap
      tabIndex={0}
      role="button"
      aria-label={`Select ${item}`}
      onClick={() => changeLangHandler(item)}
    >
      <StyledLangIcon>
        {i18n.language === item && <IconComponent name="Check" />}
      </StyledLangIcon>
      <span>
        {langObj[item]}
      </span>
    </StyledItemWrap>
  ));

  const renderHeaderBtn = (accStatus:string):React.ReactNode => {
    if ((location.pathname.includes('/create-account') && !location.pathname.includes('/create-account/select-account-type'))
    || (location.pathname.includes('/rg227/thank-you')) || (location.pathname.includes('/rg227/unsuccessful'))) return null;

    if (hasOnceFailed && !exceedFailedAttempts && !location.pathname.includes('/rg227/')) {
      return (
        <StyledContinueBtnWrap isSmallSize>
          <Button onClick={() => navigate(`/rg227/${accountId}`)} aria-label="retry assessment">
            {t('retryAssessment')}
          </Button>
        </StyledContinueBtnWrap>
      );
    }
    if (accStatus === 'Incomplete') {
      return (
        <StyledContinueBtnWrap isSmallSize>
          <Button onClick={handleIncomplete} aria-label="account status button">
            {t('continueWhereYouLeft')}
          </Button>
        </StyledContinueBtnWrap>
      );
    }
    if (accStatus === 'Reject' && !location.pathname.includes('/rg227/')) {
      return (
        <StyledContinueBtnWrap>
          <Button onClick={() => handleReject()} aria-label="account status button">
            {t('finaliseYourApplication')}
          </Button>
        </StyledContinueBtnWrap>
      );
    }
    if (accStatus === 'noAccount' && !location.pathname.includes('/create-account/select-account-type')) {
      return (
        <StyledContinueBtnWrap>
          <Button onClick={() => navigate('/create-account/select-account-type')} aria-label="account status button">
            {isMobile ? `${t('openAnAccount')}` : `${t('openALiveAccount')}`}
          </Button>
        </StyledContinueBtnWrap>
      );
    }
    return null;
  };

  // const handleOnboardingRedirect = (type:string):void => {
  //   setIsLoading(true);

  //   let url = '';

  //   switch (createAccountStatus?.type) {
  //     case 'personal':
  //       url = '/create-account/personal-account';
  //       break;
  //     case 'corporate':
  //       url = '/create-account/corporate-account';
  //       break;
  //     case 'joint':
  //       url = '/create-account/joint-account';
  //       break;
  //     default:
  //       break;
  //   }

  //   if (type === 'ai') {
  //     // use 10015 if you don't want use Face ID
  //     getJumioInfo(url, String(localStorage.getItem('accountID'))).then((res) => {
  //       if (res && res.data && res.data.data) {
  //         window.location.replace(res.data.data.herf);
  //       }
  //     });
  //   } else {
  //     setIsLoading(true);
  //     dispatch(setCreateAccountStatus(
  //       {
  //         createAccountStatus: { isModalOpen: false }
  //       }
  //     ));
  //     ErrorHandler(updateFormStep(
  //       localStorage.getItem('accountID')!,
  //       CreateAccountSteps.TradingAndEmployment,
  //       localStorage.getItem('registeredCountry')!
  //     ), dispatch)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           dispatch(setCreateAccountStatus(
  //             {
  //               createAccountStatus: { isOnBoardingMethodSelecting: false, isModalOpen: false }
  //             }
  //           ));

  //           navigate(url);
  //         }
  //       }).finally(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // };

  return isDesktop ? (
    <StyledHeaderWrap isMenuOpen={isMenuOpen} aria-label="Header wrap">
      <div>
        <IconComponent name="AcyIcon" aria-label="ACY logo" />
      </div>
      {renderHeaderBtn(accountStatus)}
      <StyledSettingWrap>
        <DropDown customMenu={customLangMenu} aria-label="language dropdown">
          <span>
            <IconComponent name="Lang" aria-label="Header language icon" />
          </span>
          <span>{langObj[i18n.language]}</span>
          <span>
            <IconComponent name="LangTriangleDown" aria-label="Header language triangle down icon" />
          </span>
        </DropDown>
      </StyledSettingWrap>
    </StyledHeaderWrap>
  ) : (
    <StyledHeaderMobileWrap aria-label="Header wrap">
      <button type="button" onClick={() => dispatch(toggleSideMenu())}>
        <IconComponent name="Hamburger" aria-label="Hamburger icon" />
      </button>
      <StyledAcyWrapper hasBtn={hasHeaderBtnStatus.includes(accountStatus)}>
        <IconComponent name={isMobile ? 'AcyLogo' : 'AcyIcon'} aria-label="ACY logo" />
      </StyledAcyWrapper>
      <StyledMobileBtnWrap>
        {renderHeaderBtn(accountStatus)}
      </StyledMobileBtnWrap>
      {!isMobile && (
        <StyledSettingWrap>
          <DropDown customMenu={customLangMenu} aria-label="language dropdown">
            <span>
              <IconComponent name="Lang" aria-label="Header language icon" />
            </span>
            <span>{langObj[i18n.language]}</span>
            <span>
              <IconComponent name="LangTriangleDown" aria-label="Header language triangle down icon" />
            </span>
          </DropDown>
        </StyledSettingWrap>
      )}
    </StyledHeaderMobileWrap>
  );
};

export default Header;

import axios from 'axios';
import { setTokenInStorage, clearStorageAndKeepNecessary } from 'utils/auth';
import { Languages } from 'constant/language';

let isRefreshing = false;
let retryAPI = false;
let refreshSubscribers: string[] = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function subscribeTokenRefresh(cb: any): void {
  refreshSubscribers.push(cb);
}

function onRefreshed(token: string): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refreshSubscribers.forEach((cb: any) => cb(token));
  refreshSubscribers = [];
}

const customInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('language') || Languages.EN
  },
  timeout: 60000
});

const token = `Bearer ${localStorage.getItem('accessToken')}`;
customInstance.defaults.headers.common.Authorization = token;

customInstance.interceptors.request.use(
  (config) => {
    if (config.headers) config.headers['Accept-Language'] = localStorage.getItem('language') || Languages.EN;
    return config;
  },

  (error) => Promise.reject(error)
);
customInstance.interceptors.response.use(
  (response) => response,

  (error) => {
    const { config, response: { status } } = error;
    const originalRequest = config;
    if (status === 401) {
      if (originalRequest.url === `/realm/${process.env.REACT_APP_REALM}/clients`) {
        return Promise.reject(error);
      }
      if (!retryAPI) {
        if (!isRefreshing) {
          isRefreshing = true;
          const headers = new Headers();
          headers.append('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
          headers.append('Accept', 'application/json');
          const init = {
            method: 'POST',
            headers,
            body: `client_id=meetup-app-pkce-client&grant_type=refresh_token&refresh_token=${localStorage.getItem('refreshToken')}`,
            mode: 'cors'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any;

          fetch(`${process.env.REACT_APP_OIC_ENDPOINT}/token`, init)
            .then((response) => response.json())
            .then((res) => {
              isRefreshing = false;
              if (res && res.access_token) {
                setTokenInStorage(res);
                onRefreshed(res.access_token);
                retryAPI = true;
                setTimeout(() => {
                  retryAPI = false;
                }, 15000);
              } else {
                clearStorageAndKeepNecessary();
                window.location.href = '/';
              }
            }).catch(() => {
              clearStorageAndKeepNecessary();
              window.location.href = '/';
            });
        }

        const retryOrigReq = new Promise((resolve) => {
          subscribeTokenRefresh((newToken: string) => {
            // replace the expired token and retry
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            resolve(axios(originalRequest));
          });
        });
        return retryOrigReq;
      }
      if (retryAPI) {
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        return axios(originalRequest).then((res) => res).catch((err) => Promise.reject(err));
      }
    }
    return Promise.reject(error);
  }
);

export default customInstance;

import { useTranslation } from 'react-i18next';
import Dialog from 'components/Dialog';
import IconComponent from 'components/Icons';
import { useLayoutControlState } from 'containers/AccountList/context/layoutControlContext';
import { getACYLocale } from 'utils/common';
import { SUPPORT_EMAIL } from 'constant/supportEmail';
import { Languages } from 'constant/language';
import { StyledContent, StyledDescription, StyledTitle } from './style';

const TradingExperienceDialog = (): JSX.Element => {
  const { i18n, t } = useTranslation('rg227Unsuccessful');
  const { isTradingExperienceDialogOpen, setIsTradingExperienceDialogOpen } = useLayoutControlState();

  return (
    <Dialog
      isOpen={isTradingExperienceDialogOpen}
      handleClose={() => setIsTradingExperienceDialogOpen(false)}
      title={t('tradingExperience')}
      contentSlot={(
        <StyledContent>
          <IconComponent
            name="WarningCircle"
            width="64"
            height="64"
            color="#F98600"
          />
          <StyledTitle>{t('applicationUnsuccessful')}</StyledTitle>
          <StyledDescription>
            <p>{t('descriptionParagraph1')}</p>
            <p>
              {t('descriptionParagraph2_1')}
              <a
                href={`https://acy.com/${getACYLocale(
                  i18n.language
                )}/market-news/education/`}
                target="_blank"
                rel="noreferrer"
              >
                {t('learn')}
              </a>
              {t('descriptionParagraph2_2')}
              <a href={`mailto:${SUPPORT_EMAIL[i18n.language as Languages]}`}>
                {SUPPORT_EMAIL[i18n.language as Languages]}
              </a>
              {t('descriptionParagraph2_3')}
            </p>
          </StyledDescription>
        </StyledContent>
      )}
    />
  );
};

export default TradingExperienceDialog;

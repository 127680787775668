import { useEffect, useState, useMemo } from 'react';
import FormPanel from 'components/FormPanel';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { getTradingPreferencesFields } from 'api/v1/account';
import { ResponseFieldsProps, OptionsProps } from 'type/form';
import { useFormContext } from 'react-hook-form';
import { useCreateJointAccountFlow } from 'store/context/hooks';
import { CreateJointAccountSteps } from 'constant/createAccount';
import FormRadioButton from 'containers/ReactHookFormInputCells/components/FormRadioButton';
import FormSelect from 'containers/ReactHookFormInputCells/components/FormSelect';
import { getFieldWarningMessage, checkFieldIsWarning, checkFieldIsCorrecting } from 'containers/CreateAccountForm/utils';
import { StyledLoadingWrap } from 'containers/CreateJointAccountForm/components/style';
import {
  StyledFormWrapper, StyledContentWrap, StyledHalfContentWrap, StyledMoreButton
} from './style';

interface InputInfoProps {
  type: string;
  name: string;
  id: string;
  placeHolder: string;
  options?: {
    value: string;
    label: string;
  }[];
  rules?: {
    pattern?: { value: string, message: string };
    maxLength?: { value: number, message: string };
    required?: { value: boolean, message: string }
  };
  errMsg?: {
    required?: string;
    pattern?: string;
  };
  moreButton: {
    name: string;
    url: string;
  } | null;
}

type OptionsTypes = OptionsProps[] | {
  MetaTrader4: OptionsProps[];
  MetaTrader5: OptionsProps[];
}[]

const TradingPreferences = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const [isTradingPreferencesLoading, setIsTradingPreferencesLoading] = useState(true);
  const [tradingPreferencesFields, setTradingPreferencesFields] = useState<ResponseFieldsProps[]>([]);

  const { rejectWarningFields } = useCreateJointAccountFlow();
  const tradingPreferencesNum = useMemo(
    () => (tradingPreferencesFields.length > 0 ? tradingPreferencesFields.length / 2 : 0),
    [tradingPreferencesFields]
  );
  const { setValue, getValues, watch } = useFormContext();
  const currentStep = CreateJointAccountSteps.JointTradingPreference;
  const currentPlatform = watch('trading_platform');
  const platformDependencies = ['funding_currency', 'leverage', 'mt_account_type'];

  const getOptions = (options: OptionsTypes): OptionsProps[] => {
    if ('MetaTrader4' in options[0] && 'MetaTrader5' in options[0]) {
      const platform = getValues('trading_platform');
      if (platform === 'MetaTrader4') return options[0]?.MetaTrader4;
      if (platform === 'MetaTrader5') return options[0]?.MetaTrader5;
      return [];
    }
    const res: OptionsProps[] = [];
    options.forEach((el) => {
      if ('label' in el && 'value' in el) res.push(el);
    });
    return res;
  };

  const renderInput = (item: InputInfoProps): JSX.Element => {
    if (item.type === 'radioButton') {
      return (
        <FormRadioButton
          key={item.id}
          id={item.id}
          required={item?.rules?.required?.value}
          options={getOptions(item.options || [])}
          label={item.name}
          labelButton={(
            <StyledMoreButton
              aria-label={`${item.id} Learn More`}
              onClick={() => {
                const windowReference = window.open();
                if (windowReference) windowReference.location = item?.moreButton?.url || '';
              }}
            >
              {item?.moreButton?.name}
            </StyledMoreButton>
          )}
          disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
        />
      );
    }
    return (
      <FormSelect
        key={item.id}
        id={item.id}
        required={item?.rules?.required?.value}
        options={getOptions(item.options || [])}
        placeHolder={item.placeHolder}
        label={item.name}
        warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, item.id)}
        disabled={checkFieldIsWarning(rejectWarningFields, currentStep, item.id)}
        markWarning={checkFieldIsCorrecting(rejectWarningFields, currentStep, item.id, getValues(item.id))}
      />
    );
  };

  useEffect(() => {
    tradingPreferencesFields.forEach((categoryObj) => {
      if (platformDependencies.includes(categoryObj?.id)) {
        const value = getValues(categoryObj.id);
        let reset = true;
        const options = getOptions(categoryObj.options);

        options.forEach((item) => {
          if (item.value === value) reset = false;
        });

        if (reset) setValue(categoryObj.id, null);
      }
    });
  }, [currentPlatform]);

  useEffect(() => {
    getTradingPreferencesFields(getValues('country')).then((res) => {
      if (res) {
        setTradingPreferencesFields(res?.data);
      }
    }).finally(() => {
      setIsTradingPreferencesLoading(false);
    });
  }, []);

  return (
    <StyledFormWrapper>
      <FormPanel title={t('tradingPreferences')}>
        <StyledContentWrap className="StyledContentWrap flx-row" gap={24}>
          {!isTradingPreferencesLoading ? (
            <>
              <StyledHalfContentWrap>
                {
                  tradingPreferencesFields.map((item: InputInfoProps, itemIndex: number) => {
                    if (tradingPreferencesNum > itemIndex) {
                      return renderInput(item);
                    }
                    return null;
                  })
                }
              </StyledHalfContentWrap>
              <StyledHalfContentWrap>
                {
                  tradingPreferencesFields.map((item: InputInfoProps, itemIndex: number) => {
                    if (tradingPreferencesNum <= itemIndex) {
                      return renderInput(item);
                    }
                    return null;
                  })
                }
              </StyledHalfContentWrap>
            </>
          ) : (
            <StyledLoadingWrap>
              <CircularProgress />
            </StyledLoadingWrap>
          )}
        </StyledContentWrap>
      </FormPanel>
    </StyledFormWrapper>
  );
};

export default TradingPreferences;

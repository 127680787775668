import cx from 'classnames';
import { CommonProps } from 'type/component';
import React, { ChangeEvent, forwardRef } from 'react';
import {
  StyledRadioGroupWrap, StyledRadioGroup, StyleBtnLabel, StyledNormalRadio, StyledTitleWrapper
} from './style';

interface RadioGroupProps extends CommonProps {
  groupName: string;
  optionType?: string; // default | button
  options: OptionsProps[];
  disabled?: boolean;
  value?: string;
  label?: string;
  id?:string;
  labelButton?: React.ReactNode;
  onChange?: (event?: ChangeEvent<HTMLInputElement>) => void;
}

interface OptionsProps {
  value: string;
  label: string;
}

const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>((props, ref): JSX.Element => {
  const {
    groupName, options, className, optionType, value, label, id, labelButton, disabled = false, onChange, ...others
  } = props;
  const renderOptions = (): React.ReactNode => {
    if (optionType === 'button') {
      return (
        options.map((optionData, index) => (
          <StyleBtnLabel
            key={`${optionData.value}-${groupName}-${index}`}
            htmlFor={`${groupName}-${optionData.value}`}
            disabled={disabled}
          >
            <input
              ref={ref}
              type="radio"
              id={`${groupName}-${optionData.value}`}
              value={optionData.value}
              name={groupName}
              disabled={disabled}
              checked={optionData.value === value}
              onChange={onChange}
              {...others}
            />
            <span
              aria-label={`${optionData.label} Option Button`}
              className={cx({ btnType: optionType === 'button' })}
            >
              {optionData.label}
            </span>
          </StyleBtnLabel>
        ))
      );
    }
    return (
      options.map((optionData, index) => (
        <StyledNormalRadio key={`${optionData.value}-${groupName}-${index}`}>
          <input
            ref={ref}
            type="radio"
            id={`${groupName}-${optionData.value}`}
            value={optionData.value}
            name={groupName}
            disabled={disabled}
            checked={optionData.value === value}
            onChange={onChange}
            {...others}
          />
          <label htmlFor={`${groupName}-${optionData.value}`}>{optionData.label}</label>
        </StyledNormalRadio>
      ))
    );
  };

  return (
    <StyledRadioGroupWrap>
      <StyledTitleWrapper>
        {label && <label htmlFor={id} aria-label={`${id} label`}>{label}</label>}
        {labelButton}
      </StyledTitleWrapper>

      <StyledRadioGroup
        optionType={optionType}
        className={cx('StyledRadioGroup', 'lp-radio-group', className)}
      >
        {renderOptions()}
      </StyledRadioGroup>
    </StyledRadioGroupWrap>
  );
});
export default RadioGroup;

import styled from 'styled-components';
import { device } from 'styles/deviceStyle';
import Modal from '@mui/material/Modal';

export const StyledModalWrapper = styled(Modal)`
  overflow: auto;
  display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledModalContent = styled.div`
  width: 430px;
  padding: 32px 32px 24px 32px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #1c1c1c;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  > div {
    display: flex;
    size: 16px;
    line-height: 24px;
    font-weight: 400;

    > svg {
      flex-shrink: 0;
      margin: ${(props) => (props.theme.isRTL ? '0 0 0 16px' : '0 16px 0 0')};
      font-size: 22px;
    }
  }

  @media ${device.mobileMaxWidth} {
    margin: auto 20px;
    font-size: 14px;
  }
`;

export const StyledLButtonsWrap = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 4px 16px;
    border-radius: 8px;
    line-height: 24px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;

    &:first-child {
      color: #145db5;
      border-color:#145db5;
      margin: ${(props) => (props.theme.isRTL ? '0 0 0 16px' : '0 16px 0 0')};
      &:hover,
      &:focus {
        color: #145db5;
        border-color: #145db5;
      }
    }
    &:last-child {
      color: #ffffff;
      border-color: #145db5;
      background-color: #145db5;

      &:hover,
      &:focus,
      &:disabled {
        color: #ffffff;
        border-color: ${(props) => props.theme.common.primary};
        background-color: ${(props) => props.theme.common.primary};
      };
      &:disabled {
        opacity: 0.6;
      }
    }
  }
`;

export const StyledPopUpContent = styled.pre`
  margin: 0;
  word-break: break-word;
  white-space: break-spaces;
  font-size: 14px;
`;

import { createSlice } from '@reduxjs/toolkit';

export interface CreateAccountStatusType {
  type:string;
  isOnBoardingMethodSelecting:boolean;
  isModalOpen:boolean;
}

// Define a type for the slice state
interface PageState {
  globalPopUp: {
    isModalOpen: boolean,
    msg: string
  },
  accountStatus: string;
  createAccountStatus: CreateAccountStatusType
  isJumioFetched?:boolean;
  activeAccountNumber: string;
}

// Define the initial state using that type
export const initialState: PageState = {
  globalPopUp: {
    isModalOpen: false,
    msg: ''
  },
  accountStatus: '',
  createAccountStatus: {
    type: '',
    isOnBoardingMethodSelecting: false,
    isModalOpen: false
  },
  isJumioFetched: false,
  activeAccountNumber: ''
};

export const pageSlice = createSlice({
  name: 'page',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGlobalPopUp: (state, action) => {
      state.globalPopUp = action.payload;
    },
    setAccountStatus: (state, action) => {
      state.accountStatus = action.payload.accountStatus;
    },
    setCreateAccountStatus: (state, action) => {
      state.createAccountStatus = {
        ...state.createAccountStatus,
        ...action.payload.createAccountStatus
      };
    },
    setIsJumioFetched: (state, action) => {
      state.isJumioFetched = action.payload.isJumioFetched;
    },
    setActiveAccountNumber: (state, action) => {
      state.activeAccountNumber = action.payload;
    }
  }
});

export const {
  setGlobalPopUp, setAccountStatus, setCreateAccountStatus, setIsJumioFetched, setActiveAccountNumber
} = pageSlice.actions;
export default pageSlice.reducer;

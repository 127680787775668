import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { useAuth } from 'store/context/hooks';
import { useAppDispatch } from 'store';
import { setActiveAccountNumber } from 'containers/Pages/slices';
import { jwtDecode, setTokenInStorage } from 'utils/auth';
import { getAccounts, getAccountPortalLoginURL } from 'api/v1/account';
import BackdropSpinner from 'components/BackdropSpinner';
import AccountStatusHandler from 'utils/AccountStatusHandler';
import i18n from 'i18n';

import { transferLangIntoCmp } from 'utils/common';

const LoginCallback = (): JSX.Element => {
  const { accessToken, updateAccessToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    data: accountsData,
    isLoading,
    error
  } = useSWR(accessToken ? ['/accounts', 'get'] : null, getAccounts, {
    dedupingInterval: 2000,
    revalidateOnFocus: false
  });

  const handleRedirect = (): void => {
    // For the common situation, it's need use getAccounts to check where should we redirect
    // Some cases need to redirect directly
    // 1. /rg227/{{accountID}}
    // 2. /create-account/incomplete
    let commonRedirect = true;
    const path = process.env.REACT_APP_CALLBACK_PATH
      && location.pathname.split(process.env.REACT_APP_CALLBACK_PATH)[1];
    if (path?.includes('rg227')) commonRedirect = false;
    if (path?.includes('/redirect/client-cloudhub')) commonRedirect = false;
    if (path === '/create-account/incomplete') commonRedirect = false;

    if (commonRedirect) {
      if (accountsData && !isLoading) {
        dispatch(
          setActiveAccountNumber(
            accountsData.data
              .filter(
                (el: { [key: string]: string }) => el?.status === 'Active' || el?.status === 'Auto_Active'
              )
              .length.toString()
          )
        );

        if (accountsData.data && accountsData.data.length > 1) { navigate('/account-list'); }
        const target: { [key: string]: { [key: string]: string } } = {};
        accountsData.data?.forEach((el: { [key: string]: string }) => {
          target[`${el.type}_${el.status}`] = {
            accountID: el.id,
            formStep: el.form_step
          };
        });

        const { accountStatus } = AccountStatusHandler(target, dispatch);

        if (accountsData.data.length === 1 && (accountStatus === 'Active' || accountStatus === 'Auto_Active')) {
          getAccountPortalLoginURL(accountsData.data[0].id).then((res) => {
            if (res && res.status === 200) { window.location.assign(res.data.login_url); }
          });
          navigate('/account-list');
        }

        navigate('/');
      }
      if (error) dispatch(setActiveAccountNumber('0'));
    } else if (path) navigate(path);
  };

  const handleFetchAccessToken = (
    authorizationCode: string | null,
    codeVerifier: string | null
  ): void => {
    const headers = new Headers();
    headers.append(
      'Content-type',
      'application/x-www-form-urlencoded; charset=UTF-8'
    );
    headers.append('Accept', 'application/json');

    const body = {
      grant_type: 'authorization_code',
      client_id: 'meetup-app-pkce-client',
      code: authorizationCode,
      code_verifier: codeVerifier,
      redirect_uri: window.location.href.split('?')[0]
    };
    /* x-www-form-urlencoded : */
    const formBody: string[] = [];

    Object.keys(body).forEach((eachKey: string) => {
      const encodedKey = encodeURIComponent(eachKey);
      const val = body[eachKey as keyof typeof body];
      if (val) formBody.push(`${encodedKey}=${encodeURIComponent(val)}`);
    });

    fetch(`${process.env.REACT_APP_OIC_ENDPOINT}/token`, {
      method: 'POST',
      headers,
      body: formBody.join('&'),
      mode: 'cors'
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.access_token) {
          setTokenInStorage(json);
          updateAccessToken(json.access_token);
          const decodedObj = jwtDecode(json.access_token);
          localStorage.setItem('registeredCountry', decodedObj.country);
          localStorage.setItem('firstName', decodedObj.given_name);
          localStorage.setItem(
            'language',
            transferLangIntoCmp(decodedObj.locale)
          );
          localStorage.setItem('uid', decodedObj.sub);
          i18n.changeLanguage(transferLangIntoCmp(decodedObj.locale));
        } else {
          window.location.href = '/';
        }
      })
      .catch(() => {
        window.location.href = '/';
      });
  };

  useEffect(() => {
    handleRedirect();
  }, [accountsData, isLoading]);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) navigate('/');
    if (!localStorage.getItem('accessToken')) {
      const urlParams = new URLSearchParams(window.location.search);
      const urlParamsInfo = {
        authorizationCode: urlParams.get('code'),
        returnedState: urlParams.get('state'),
        error: urlParams.get('error'),
        errorDescription: urlParams.get('error_description')
      };
      handleFetchAccessToken(
        urlParamsInfo.authorizationCode,
        localStorage.getItem('codeVerifier')
      );
    }
  }, []);

  return <BackdropSpinner open />;
};
export default LoginCallback;

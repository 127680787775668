import React, {
  useMemo, useEffect, useState, useRef
} from 'react';
import redirectToKeycloakLogin from 'utils/redirectToKeycloakLogin';
import { clearStorageAndKeepNecessary } from 'utils/auth';
import { AuthContextType } from '../types';

export const AuthContext = React.createContext({} as AuthContextType);

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [accessToken, setAccessToken] = useState<string>(localStorage.getItem('accessToken') || '');
  const skipInit = useRef(false);
  const { children } = props;

  const handleLogout = (): void => {
    skipInit.current = true;
    if (localStorage.getItem('id_token')) {
      let url = `${process.env.REACT_APP_OIC_ENDPOINT}/logout`;
      url += `?id_token_hint=${localStorage.getItem('id_token')}&post_logout_redirect_uri=${process.env.REACT_APP_ROOT_URL}`;
      clearStorageAndKeepNecessary();
      window.location.href = url;
    } else {
      clearStorageAndKeepNecessary();
      window.location.href = '/';
    }
  };

  const contextValue: AuthContextType = useMemo(() => ({
    accessToken,
    handleLogout,
    updateAccessToken: (val:string): void => {
      setAccessToken(val);
    }
  }), [accessToken]);

  useEffect(() => {
    // The useEffect of the children component will execute earlier. If handleLogout has already been executed,
    // then init will not be executed again.
    if (skipInit.current) return;

    // The /login/callback endpoint is specifically designed to handle the scenario when the user is redirected
    // from Keycloak login, so the logic for checking is different from the general case.
    if (window.location.pathname.includes('/login/callback')) return;

    // Normally, if there is no accessToken, the user will be redirected to the Keycloak login page.
    // However, there are some pages that can be accessed without logging in, so this also needs to
    // be taken into consideration in the judgement.
    if (!localStorage.getItem('accessToken')) {
      if (window.location.pathname === '/open-live-account' || window.location.pathname.includes('/correction')) {
        return;
      }
      redirectToKeycloakLogin();
    }
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

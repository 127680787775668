import styled from 'styled-components';

export const StyledCard = styled.div<{ expired?: boolean }>`
  background: ${(props) => (props.expired ? '#FBFBFB' : '#FFFFFF')};
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: ${(props) => (props.expired ? '#BBBBBB' : '#1C1C1C')};
`;

export const StyledCardTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const StyledBalance = styled.div<{ expired?: boolean }>`
  color: ${(props) => (props.expired ? '#BBBBBB' : '#5C5C5C')};
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;

  + span {
    color: ${(props) => (props.expired ? '#BBBBBB' : '#145DB5')};
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }
`;

export const StyledDetailBlock = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const StyledDetailTitle = styled.div<{ expired?: boolean }>`
  color: ${(props) => (props.expired ? '#BBBBBB' : '#5C5C5C')};
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
`;

export const StyledDetailContent = styled.div`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
`;

export const StyledDetailItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledCheckIcon = styled.div<{ expired?: boolean }>`
  display: flex;
  align-items: center;

  > svg {
    path {
      fill: ${(props) => (props.expired ? '#BBBBBB' : '#5C5C5C')};
    }
  }
`;

export const StyledFooter = styled.div<{expired?: boolean}>`
  margin-top: 16px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  border-top: 1px solid #F4F4F4;

  div + span {
    padding: ${(props) => (props.theme.isRTL ? '0 4px 0 0' : '0 0 0 4px')};
    display: flex;
    flex: 1;
    color: ${(props) => (props.expired ? '#BBB' : '#5C5C5C')};
  }

  span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #145DB5;
  }
`;

export const StyledAddContainer = styled.span`
 display: flex;
 align-items: center;
 cursor: pointer
`;

export const StyledAdd = styled.span`
 margin-right: 2px;
`;

export const StyledFeatureIcon = styled.div`
  position: absolute;
  top: 16px;
  right: ${(props) => (props.theme.isRTL ? 'none' : '24px')};
  left: ${(props) => (props.theme.isRTL ? '24px' : 'none')};
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #5C5C5C;
    }
  }
`;

export const StyledHeadWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
`;

export const StyledTag = styled.div<{ expired?: boolean }>`
  display: flex;
  align-items: center;
  margin: 4.5px 0 4.5px 8px;
  line-height: 11px;
  padding: 2px 4px;
  font-size: 8px;
  border-radius: 10px;
  color: ${(props) => (props.expired ? '#BBB' : '#145db5')};
  border: ${(props) => (props.expired ? '1px solid #BBB' : '1px solid #145db5')};
`;

export const StyledHeaderPanel = styled.div`
  padding: 11px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #FFF;
  background-color: #145DB5;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const StyledContentContainer = styled.div`
  padding: 16px 24px;
  position: relative;
`;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Paper from 'components/Paper';
import Icon from 'components/Icons';
import SidesIcons from 'components/SidesIcons';
import CheckEmailForm from 'containers/RegisterFormContainers/CheckEmailForm';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import VerifyCode from 'containers/RegisterFormContainers/components/VerifyCode';
import { useState, useEffect } from 'react';
import DropDown from 'components/DropDown';
import { useTranslation } from 'react-i18next';
import { Languages } from 'constant/language';
import Button from '@mui/material/Button';
import PopUp from 'components/PopUp';
import { transferLangIntoCmp, isStorageExpiry } from 'utils/common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  StyledRegisterContainer, StyledContent, StyledDropdownWrap, StyledLangWrap, StyledPopUpBtnWrap,
  StyledHeaderLogoLangs, StyledContentWrapper
} from './style';

const langObj: { [lang: string]: string } = {
  [Languages.EN]: 'English',
  [Languages.ZH_CN]: '中文简体',
  [Languages.ZH_TW]: '中文繁體',
  [Languages.AR]: 'العربية',
  [Languages.ID]: 'Bahasa',
  [Languages.VI]: 'Tiếng Việt',
  [Languages.ES]: 'Española',
  [Languages.KO]: '한국인',
  [Languages.PT]: 'Português',
  [Languages.FR]: 'Français',
  [Languages.JP]: '日本語',
  [Languages.MS]: 'Melayu',
  [Languages.HE]: 'עִברִית'
};

enum RegisterStep {
  CLIENT_INFO = 'CLIENT_INFO',
  VERIFICATION = 'VERIFICATION'
}

const RegisterContainers = ():JSX.Element => {
  const { t, i18n } = useTranslation('createAccount');
  const methods = useForm({ mode: 'onChange' });
  const [step, setStep] = useState(RegisterStep.CLIENT_INFO);
  const [popupTxt, setPopupTxt] = useState<string>('');
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [mobileCountryCode, setMobileCountryCode] = useState<string>('');
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const curLang = i18n.language;

  const handleLang = (lang:string):void => {
    localStorage.setItem('language', lang);
    navigator(0);
  };

  const setWithExpiry = (storageKey: string, value: string, ttl: number): void => {
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + ttl // ttl msec
    };
    localStorage.setItem(storageKey, JSON.stringify(item));
  };

  let affiliateLeadSourceKey = searchParams.keys().next().value;
  if (affiliateLeadSourceKey === 'next' || affiliateLeadSourceKey === 'locale' || affiliateLeadSourceKey === 'url') affiliateLeadSourceKey = '';

  if (isStorageExpiry('affiliateID')) {
    localStorage.removeItem('affiliateID');
  }
  if (affiliateLeadSourceKey && !localStorage.getItem('affiliateID')) {
    setWithExpiry('affiliateID', window.location.search, 30 * 24 * 60 * 60 * 1000); //  30 days to expire;
  }

  const onSubmit: SubmitHandler<object> = (data) => {
    console.info(data);
  };
  const handleContinue = (): void => {
    setStep(RegisterStep.VERIFICATION);
    // Send verify code
  };
  const handleBack = (): void => {
    setStep(RegisterStep.CLIENT_INFO);
  };

  const handlePopupErrMessage = (errMessage:string):void => {
    setPopupOpen(true);
    setPopupTxt(errMessage);
  };

  const handleMobileCountryCode = (countryCode: string): void => {
    setMobileCountryCode(countryCode);
  };

  const customMenu = [
    Object.keys(langObj).map((item) => (
      <button type="button" onClick={() => handleLang(item)} key={item}>
        {langObj[item]}
      </button>
    ))
  ];

  useEffect(() => {
    if (!localStorage.getItem('language') && !searchParams.get('locale')) {
      const lowerCaseBrowserLangList = window.navigator.languages;
      let lowerCaseBrowserLang = '';
      const languageArr = [...Object.values(Languages), 'zh-cn', 'zh-tw'] as string[];
      lowerCaseBrowserLangList.some((eachLang) => {
        lowerCaseBrowserLang = eachLang.toLocaleLowerCase();
        return languageArr.includes(eachLang.toLocaleLowerCase());
      });

      if (lowerCaseBrowserLang.includes('-') && lowerCaseBrowserLang !== 'zh-tw' && lowerCaseBrowserLang !== 'zh-cn') {
        [lowerCaseBrowserLang] = lowerCaseBrowserLang.split('-');
      }
      const browserLang = transferLangIntoCmp(lowerCaseBrowserLang);
      localStorage.setItem('language', browserLang);
      i18n.changeLanguage(transferLangIntoCmp(browserLang));
    }
  }, []);

  return (
    <StyledRegisterContainer>
      <SidesIcons />
      <Paper color="#FFFFFF" className="customPaper">
        <StyledContentWrapper>
          <StyledHeaderLogoLangs>
            <Icon name={process.env.REACT_APP_REALM === 'ubank' ? 'UBankIcon' : 'AcyIcon'} aria-label="Header logo" />
            <StyledDropdownWrap>
              <DropDown customMenu={customMenu} aria-label="Language">
                <StyledLangWrap>
                  <Icon name="LangIcon" />
                  <span>{langObj[curLang] || 'English'}</span>
                  <Icon name="DownArrow" />
                </StyledLangWrap>
              </DropDown>
            </StyledDropdownWrap>
          </StyledHeaderLogoLangs>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <StyledContent show={step === RegisterStep.CLIENT_INFO}>
                <CheckEmailForm
                  handleContinue={handleContinue}
                  handleMobileCountryCode={handleMobileCountryCode}
                  handlePopupErrMessage={handlePopupErrMessage}
                />
              </StyledContent>
              {step === RegisterStep.VERIFICATION
                && (
                <VerifyCode
                  handleBack={handleBack}
                  handlePopupErrMessage={handlePopupErrMessage}
                  mobileCountryCode={mobileCountryCode}
                />
                )}
            </form>
          </FormProvider>
        </StyledContentWrapper>
      </Paper>
      <PopUp
        content={popupTxt}
        openModal={popupOpen}
      >
        <StyledPopUpBtnWrap curLang={curLang} ubank={process.env.REACT_APP_REALM === 'ubank'}>
          <Button
            aria-label="Pop Up Cancel"
            onClick={() => {
              setPopupOpen(false);
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            aria-label="Pop Up Confirm"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_ROOT_URL}`;
            }}
          >
            {' '}
            {t('login')}
          </Button>
        </StyledPopUpBtnWrap>
      </PopUp>
    </StyledRegisterContainer>
  );
};

export default RegisterContainers;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Languages } from 'constant/language';

export const EMAIL_PATTERN = /\S+@\S+\.\S+/;
export const NAME_PATTERN = /^([^0-9]*)$/;
export const PASSWORD_PATTERN = /^(?=.*[~`!@#$%^&*()_\\\-+={[}\]|:;"'<,>.?/])(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\S]{8,20}$/;

export const isValidEmail = (email: string): boolean => EMAIL_PATTERN.test(email);

export const isValidName = (name: string): boolean => NAME_PATTERN.test(name);

export const isLenNotOverForty = (str: string): boolean => str.length <= 40;

export const isValidPassword = (password: string): boolean => PASSWORD_PATTERN.test(password);

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formatBackendID = (key: string): string => {
  let finalTxt = '';
  if (key.includes('_')) {
    key.split('_').forEach((eachPart) => {
      finalTxt += `${
        eachPart.charAt(0).toLocaleUpperCase() + eachPart.slice(1)
      } `;
    });
  } else {
    finalTxt += key.charAt(0).toLocaleUpperCase() + key.slice(1);
  }

  return finalTxt.trim();
};

export const transferLangIntoCmp = (lang: string | null): string => {
  if (!lang) return 'en';
  const languageArr = Object.values(Languages) as string[];
  if (lang === 'cn' || lang === 'zh-cn') return 'zh';
  if (lang === 'tw' || lang === 'zh-hant' || lang === 'zh-tw') return 'zh_TW';
  if (languageArr.includes(lang)) return lang;
  return 'en';
};

export const getACYLocale = (lang: string): string => {
  if (lang === Languages.ZH_TW) return 'zh-hant';
  if (lang === Languages.HE) return Languages.EN;
  return lang;
};

export const isStorageExpiry = (key: string): boolean | null => {
  const itemStr = localStorage.getItem(key)!;
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  return now.getTime() > item.expiry;
};

export const handleSignupPageUrl = (): string => {
  const affiliateInStorage = JSON.parse(localStorage.getItem('affiliateID')!)?.value ?? null;
  if (affiliateInStorage && !isStorageExpiry('affiliateID')) {
    return `${process.env.REACT_APP_ROOT_URL}/open-live-account${affiliateInStorage}`;
  }
  return `${String(process.env.REACT_APP_ROOT_URL)}/open-live-account`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const flatFormData = (formData: FormData, key: string, value: any): void => {
  if (value === null || value === undefined) {
    formData.append(key, '');
    return;
  }

  if (value instanceof Object && !(value instanceof File)) {
    Object.keys(value).forEach((subKey: string) => {
      flatFormData(formData, `${key}[${subKey}]`, value[subKey]);
    });
  } else {
    formData.append(key, value);
  }
};

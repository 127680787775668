import styled from 'styled-components';

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const StyledItemLabel = styled.div`
  .title {
    color: #1C1C1C;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledMoreButton = styled.div`
  font-size: 12px;
  color: #006acc;
  cursor: pointer;

  &:hover {
    color: #339dff;
  }
`;

import { useEffect, useState } from 'react';
import FormPanel from 'components/FormPanel';
import { OptionsProps, ResponseFieldsProps } from 'containers/CreateAccountForm/type';
import ItemLabel from 'components/ItemLabel';
import Select from 'components/Select';
import { useFormContext, Controller } from 'react-hook-form';
import RadioGroup from 'components/RadioGroup';
import { useTranslation } from 'react-i18next';
import { getTradingPreferencesFields, getEmploymentFields } from 'api/v1/account';
import CircularProgress from '@mui/material/CircularProgress';
import IconComponent from 'components/Icons';
import { useCreateAccountFlow } from 'store/context/hooks';
import { CreateAccountSteps } from 'constant/createAccount';
import { getFieldWarningMessage, checkFieldIsWarning } from 'containers/CreateAccountForm/utils';
import { formatBackendID } from 'utils/common';
import Info from './Info';
import { StyledContentWrap, StyledLoadingWrap, StyledFormItemWrap } from '../style';
import {
  StyledTradingEmployment, StyledTips, StyledFieldContent, StyledRadioGroupWrapper
} from './style';

type OptionsTypes = OptionsProps[] | {
  MetaTrader4: OptionsProps[];
  MetaTrader5: OptionsProps[];
  LogixTrader: OptionsProps[];
}[]

const TradingEmployment = (): JSX.Element => {
  const { t } = useTranslation('registrationForm');
  const {
    control, formState: { errors }, getValues, watch, setValue
  } = useFormContext();
  const [tradingPreference, setTradingPreference] = useState<ResponseFieldsProps[]>([]);
  const [employment, setEmployment] = useState<ResponseFieldsProps[]>([]);
  const [loading, setLoading] = useState(true);
  const {
    showTip, rejectWarningFields, updateShowTip, updateFormIsLoading
  } = useCreateAccountFlow();
  const platformDependencies = ['funding_currency', 'leverage', 'mt_account_type'];

  const currentPlatform = watch('trading_platform');
  const currentStep = CreateAccountSteps.TradingAndEmployment;

  useEffect(() => {
    const country = getValues('country');
    const requestOne = getTradingPreferencesFields(country);
    const requestTwo = getEmploymentFields(country);
    updateFormIsLoading(true);
    Promise.all([requestOne, requestTwo]).then((res) => {
      if (res) {
        setTradingPreference(res[0]?.data || []);
        setEmployment(res[1]?.data || []);
      }
    }).finally(() => {
      setLoading(false);
      updateFormIsLoading(false);
    });

    return () => {
      updateShowTip(false);
    };
  }, []);

  const getOptions = (options: OptionsTypes): OptionsProps[] => {
    if ('MetaTrader4' in options[0] && 'MetaTrader5' in options[0]) {
      const platform = getValues('trading_platform');
      if (platform === 'MetaTrader4') return options[0]?.MetaTrader4;
      if (platform === 'MetaTrader5') return options[0]?.MetaTrader5;
      if (platform === 'LogixTrader') return options[0]?.LogixTrader;
      return [];
    }
    const res: OptionsProps[] = [];
    options.forEach((el) => {
      if ('label' in el && 'value' in el) res.push(el);
    });
    return res;
  };

  useEffect(() => {
    tradingPreference.forEach((categoryObj) => {
      if (platformDependencies.includes(categoryObj?.id)) {
        const value = getValues(categoryObj.id);
        let reset = true;
        const options = getOptions(categoryObj.options);

        options.forEach((item) => {
          if (item.value === value) reset = false;
        });

        if (reset) setValue(categoryObj.id, null);
      }
    });
  }, [currentPlatform]);

  return (
    <StyledTradingEmployment className="StyledTradingEmployment">
      <StyledTips showTip={showTip}>
        <IconComponent name="Alert" />
        <span>{t('changeCountryAlert')}</span>
      </StyledTips>
      <StyledFieldContent>
        <FormPanel id="Trading Preferences" title={t('tradingPreferences')}>
          <StyledContentWrap className="StyledContentWrap">
            {
              !loading
                ? tradingPreference.map((categoryObj, index) => {
                  if (categoryObj.type === 'select') {
                    return (
                      <ItemLabel
                        id={formatBackendID(categoryObj.id)}
                        key={`${categoryObj.name}-${index}`}
                        title={categoryObj.name}
                        remarkText={categoryObj?.moreButton?.name}
                        remarkLink={categoryObj?.moreButton?.url}
                      >
                        <Controller
                          control={control}
                          name={categoryObj.id}
                          rules={{ required: categoryObj?.rules?.required?.value }}
                          render={({
                            field: {
                              onChange, value
                            }
                          }) => (
                            <StyledFormItemWrap>
                              <Select
                                id={formatBackendID(categoryObj.id)}
                                options={getOptions(categoryObj.options)}
                                inputHeight="48px"
                                placeholder={categoryObj.placeHolder}
                                onChange={onChange}
                                currentValue={value}
                                defaultValue={value}
                                errorMsg={errors[categoryObj.id] && 'Error'}
                                warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, categoryObj.id)}
                                disabled={checkFieldIsWarning(rejectWarningFields, currentStep, categoryObj.id)}
                              />
                              <Info type={categoryObj.id} value={value} />
                            </StyledFormItemWrap>
                          )}
                        />

                      </ItemLabel>
                    );
                  }
                  return (
                    <ItemLabel
                      id={categoryObj.id}
                      key={`${categoryObj.name}-${index}`}
                      title={categoryObj.name}
                      remarkText={categoryObj?.moreButton?.name}
                      remarkLink={categoryObj?.moreButton?.url}
                    >
                      <Controller
                        control={control}
                        name={categoryObj.id}
                        rules={{ required: categoryObj?.rules?.required?.value }}
                        render={({
                          field: {
                            onChange, value
                          }
                        }) => (
                          <StyledRadioGroupWrapper>
                            <RadioGroup
                              options={getOptions(categoryObj.options)}
                              groupName={categoryObj.id}
                              optionType="button"
                              onChange={onChange}
                              value={value}
                              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, categoryObj.id)}
                            />
                            <Info type={categoryObj.id} value={value} />
                            <div className="optionBtnErr">
                              {errors[categoryObj.id] && 'Error'}
                            </div>
                          </StyledRadioGroupWrapper>
                        )}
                      />
                    </ItemLabel>
                  );
                })
                : (
                  <StyledLoadingWrap>
                    <CircularProgress />
                  </StyledLoadingWrap>
                )
            }
          </StyledContentWrap>
        </FormPanel>
        <FormPanel id="employment" title={t('employment')}>
          <StyledContentWrap className="StyledContentWrap">
            {
              !loading
                ? employment.map((categoryObj, index) => {
                  if (categoryObj.type === 'select') {
                    return (
                      <ItemLabel id={categoryObj.id} key={`${categoryObj.name}-${index}`} title={categoryObj.name}>
                        <Controller
                          control={control}
                          name={categoryObj.id}
                          rules={{ required: categoryObj?.rules?.required?.value }}
                          render={({
                            field: {
                              onChange, value
                            }
                          }) => (
                            <StyledFormItemWrap>
                              <Select
                                options={categoryObj.options}
                                inputHeight="48px"
                                placeholder={categoryObj.placeHolder}
                                onChange={onChange}
                                currentValue={value}
                                defaultValue={value}
                                errorMsg={errors[categoryObj.id] && 'Error'}
                                id={formatBackendID(categoryObj.id)}
                                warningMsg={getFieldWarningMessage(rejectWarningFields, currentStep, categoryObj.id)}
                                disabled={checkFieldIsWarning(rejectWarningFields, currentStep, categoryObj.id)}
                              />
                            </StyledFormItemWrap>
                          )}
                        />

                      </ItemLabel>
                    );
                  }
                  return (
                    <ItemLabel id={formatBackendID(categoryObj.id)} key={`${categoryObj.name}-${index}`} title={categoryObj.name}>
                      <Controller
                        control={control}
                        name={categoryObj.id}
                        rules={{ required: categoryObj?.rules?.required?.value }}
                        render={({
                          field: {
                            onChange, value
                          }
                        }) => (
                          <StyledRadioGroupWrapper>
                            <RadioGroup
                              options={categoryObj.options}
                              groupName={categoryObj.id}
                              optionType="button"
                              onChange={onChange}
                              value={value}
                              disabled={checkFieldIsWarning(rejectWarningFields, currentStep, categoryObj.id)}
                            />
                            <div className="optionBtnErr">
                              {errors[categoryObj.id] && 'Error'}
                            </div>
                          </StyledRadioGroupWrapper>
                        )}
                      />
                    </ItemLabel>
                  );
                })
                : (
                  <StyledLoadingWrap>
                    <CircularProgress />
                  </StyledLoadingWrap>
                )
            }
          </StyledContentWrap>
        </FormPanel>
      </StyledFieldContent>

    </StyledTradingEmployment>
  );
};

export default TradingEmployment;

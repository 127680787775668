/* eslint-disable quote-props */
import { useState, useEffect, KeyboardEvent } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Languages } from 'constant/language';
import DateRange from '@mui/icons-material/DateRange';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import {
  StyledWrap, StyledErrMsg, StyledWarningMsg
} from './style';
import 'dayjs/locale/fr';
import 'dayjs/locale/ar-sa';
import 'dayjs/locale/es';
import 'dayjs/locale/ko';
import 'dayjs/locale/ja';
import 'dayjs/locale/id';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/ms';
import 'dayjs/locale/pt';
import 'dayjs/locale/vi';
import 'dayjs/locale/he';

interface DatePickerProps {
  label?: string;
  className?: string;
  defaultValue?: string;
  errorMsg?: string;
  onChange?: (value: string) => void;
  disabled?:boolean;
  warningMsg?: string | string[];
  markWarning?: boolean;
  id?:string;
  defaultCalendarMonth?: Dayjs;
  maxDate?: Dayjs;
}

interface LangListT {
 [key: string]: string;
}
const langList: LangListT = {
  'zh': 'zh-cn',
  'zh_TW': 'zh-tw',
  'fr': 'fr',
  'ja': 'ja-JP',
  'ko': 'ko',
  'ar': 'ar-sa',
  'id': 'id',
  'es': 'es',
  'ms': 'ms',
  'pt': 'pt',
  'he': 'he',
  'vi': 'vi',
  'en': 'en'
};

const CustomDatePicker = (props: DatePickerProps): JSX.Element => {
  const curLang = localStorage.getItem('language') || Languages.EN;
  const {
    label, className, defaultValue, onChange, errorMsg, disabled,
    warningMsg, markWarning, id = '', defaultCalendarMonth, maxDate
  } = props;
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [openPicker, setOpenpicker] = useState<boolean>(false);
  useEffect(() => {
    if (defaultValue) setStartDate(dayjs(defaultValue));
    else setStartDate(null);
  }, [defaultValue]);

  const renderWarningMsg = ():React.ReactNode => {
    if (Array.isArray(warningMsg)) {
      return warningMsg.map((eachMsg:string) => (
        <StyledWarningMsg key={eachMsg} markWarning={markWarning} aria-label={`${label} Error Message`}>
          {eachMsg}
        </StyledWarningMsg>
      ));
    }
    return (
      <StyledWarningMsg markWarning={markWarning} aria-label={`${label} Error Message`}>
        {warningMsg}
      </StyledWarningMsg>
    );
  };

  const onKeyDown = (e:KeyboardEvent<HTMLImageElement>):void => e.preventDefault();

  return (
    <StyledWrap label={label} className={className} isErr={!!errorMsg} isWarn={!!warningMsg} markWarning={markWarning}>
      {label && <label aria-label={`${id} date picker title`} htmlFor={id}>{label}</label>}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={langList[curLang]}>
        <DesktopDatePicker
          className="desktop-date-picker"
          inputFormat="YYYY/MM/DD"
          value={startDate}
          defaultCalendarMonth={defaultCalendarMonth || dayjs().subtract(18, 'year')}
          maxDate={maxDate || dayjs().subtract(18, 'year')}
          onChange={(newValue: Dayjs | null) => {
            setStartDate(newValue);
            if (onChange) {
              onChange(newValue ? dayjs(newValue).format('YYYY-MM-DD') : '');
            }
          }}
          components={{
            OpenPickerIcon: DateRange
          }}
          onClose={() => setOpenpicker(false)}
          open={openPicker}
          disabled={disabled}
          renderInput={(params) => <TextField {...params} onKeyDown={onKeyDown} onClick={() => setOpenpicker(true)} />}
        />
        <div className="date-picker-input-msg-wrap">
          {errorMsg && (
          <StyledErrMsg>
            {errorMsg}
          </StyledErrMsg>
          )}
          { warningMsg && renderWarningMsg()}
        </div>
      </LocalizationProvider>
    </StyledWrap>

  );
};

export default CustomDatePicker;

/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconComponent from 'components/Icons';

import {
  StyledCard,
  StyledHeadWrapper,
  StyledCardTitle,
  StyledTag,
  StyledBalance,
  StyledDetailBlock,
  StyledDetailItem,
  StyledDetailTitle,
  StyledDetailContent,
  StyledFeatureIcon,
  StyledFooter,
  StyledCheckIcon,
  StyledAdd,
  StyledAddContainer,
  StyledHeaderPanel,
  StyledContentContainer
} from './style';

interface DemoAccountCardProps {
  account: {
    [key: string]: string;
  }
  expired?: boolean;
  onCardClick: (clickedAccount: { id: string; name: string }) => void;
  handleOpenLiveAccount?: ()=> void;
  handleAddVirtualFunds?: ()=> void;
}

const DemoAccountCard: React.FC<DemoAccountCardProps> = ({
  account: {
    account_name,
    account_number,
    total_balance,
    server_name,
    funds,
    trading_platform,
    days_left
  },
  expired = false,
  onCardClick,
  handleOpenLiveAccount,
  handleAddVirtualFunds
}) => {
  const { t } = useTranslation('demoAccount');

  const handleClickEvent = expired ? handleOpenLiveAccount : handleAddVirtualFunds;

  return (
    <StyledCard key={account_number} expired={expired}>
      <StyledHeaderPanel>
        {trading_platform}
      </StyledHeaderPanel>
      <StyledContentContainer>
        <StyledHeadWrapper>
          <StyledCardTitle aria-label="account name">
            {account_name}
          </StyledCardTitle>
          <StyledTag aria-label="demo icon" expired={expired}>
            Demo
          </StyledTag>
        </StyledHeadWrapper>
        <StyledBalance aria-label="total balance title" expired={expired}>
          {t('totalBalance')}
        </StyledBalance>
        <span aria-label="total balance">{total_balance}</span>
        <StyledDetailBlock>
          <StyledDetailItem>
            <StyledDetailTitle aria-label="server name title" expired={expired}>
              {t('server')}
            </StyledDetailTitle>
            <StyledDetailContent aria-label="server name">
              {server_name}
            </StyledDetailContent>
          </StyledDetailItem>
          <StyledDetailItem>
            <StyledDetailTitle aria-label="funds title" expired={expired}>
              {t('funds')}
            </StyledDetailTitle>
            <StyledDetailContent aria-label="funds">
              {funds}
            </StyledDetailContent>
          </StyledDetailItem>
        </StyledDetailBlock>
        <StyledDetailBlock>
          <StyledDetailItem>
            <StyledDetailTitle aria-label="account number title" expired={expired}>
              {t('number')}
            </StyledDetailTitle>
            <StyledDetailContent aria-label="account number">
              {account_number}
            </StyledDetailContent>
          </StyledDetailItem>
          <StyledFeatureIcon
            aria-label={expired ? 'delete' : 'Settings'}
            onClick={() => onCardClick({ id: account_number || '', name: account_name || '' })}
          >
            <IconComponent name={expired ? 'Delete' : 'Settings'} />
          </StyledFeatureIcon>
        </StyledDetailBlock>
        <StyledFooter expired={expired}>
          <StyledCheckIcon aria-label="Check Icon Wrap" expired={expired}>
            <IconComponent name="Clock" />
          </StyledCheckIcon>
          <span aria-label="days left">{expired ? t('expired') : days_left}</span>
          <StyledAddContainer>
            <StyledAdd onClick={handleClickEvent}>{expired ? t('openLiveAccount') : t('addVirtualFunds')}</StyledAdd>
            <IconComponent name="ArrowRight" />
          </StyledAddContainer>
        </StyledFooter>
      </StyledContentContainer>
    </StyledCard>
  );
};

export default DemoAccountCard;

import axios from 'api/axios';
import axiosWithoutLogin from 'api/axiosWithoutLogin';
import { AxiosPromise } from 'axios';
import { IFormInput } from 'containers/RegisterFormContainers/CheckEmailForm';

interface sendEmailVerificationCodeProps {
  email: string;
  firstName: string;
  lastName: string
}

interface sendMobileVerificationCodeProps {
  mobileCountryCode: string;
  mobileNumber: string;
}

interface userInfo extends IFormInput {
  verificationCode: string;
  signupPageUrl: string;
  lastViewUrl: string;
  demoCookieUrl: string;
  verificationType: string;
  mobileNumber: string;
  mobileCountryCode: string;
}

export const userRegister = (registerData: userInfo): AxiosPromise => axios({
  method: 'POST',
  url: '/realm/acy/clients',
  data: {
    email: registerData.email,
    password: registerData.password,
    country: registerData.country,
    first_name: registerData.firstName,
    last_name: registerData.lastName,
    signup_page_url: registerData.signupPageUrl,
    last_view_url: registerData.lastViewUrl,
    verification_code: registerData.verificationCode,
    demo_cookie_url: registerData.demoCookieUrl,
    policy: registerData.policy,
    subscription: registerData.subscription,
    verification_type: registerData.verificationType,
    mobile_number: registerData.mobileNumber,
    mobile_country_code: registerData.mobileCountryCode
  }
});

export const serchClientByEmail = (email: string): AxiosPromise => axios({
  method: 'GET',
  url: `/realm/acy/clients?email=${email}`
});

export const getCountryList = (): AxiosPromise => axios({
  method: 'GET',
  url: '/countries'
});

export const getSanctionedCountryList = (): AxiosPromise => axios({
  method: 'GET',
  url: '/sanctioned-countries'
});

export const sendEmailVerificationCode = (data: sendEmailVerificationCodeProps): AxiosPromise => axios({
  method: 'POST',
  url: `/realm/${process.env.REACT_APP_REALM}/email-verification-code`,
  data: {
    email: data.email,
    first_name: data.firstName,
    last_name: data.lastName
  }
});

export const sendMobileVerificationCode = (data: sendMobileVerificationCodeProps): AxiosPromise => axiosWithoutLogin({
  method: 'POST',
  url: `/realm/${process.env.REACT_APP_REALM}/sms-verification-code`,
  data: {
    mobile_country_code: data.mobileCountryCode,
    mobile_number: data.mobileNumber
  }
});

export const getCountryByIP = (ip: string): AxiosPromise => axiosWithoutLogin({
  method: 'GET',
  url: '/ipaddress/countries',
  params: {
    ip_address: ip
  }
});

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Languages } from '../constant/language';
import en from './language/en';
import zh from './language/zhCn';
import zh_TW from './language/zhTw';
import ja from './language/jp';
import ar from './language/ar';
import fr from './language/fr';
import ko from './language/ko';
import id from './language/id';
import es from './language/es';
import ms from './language/ms';
import pt from './language/pt';
import he from './language/he';
import vi from './language/vi';

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('language') || Languages.EN,
  fallbackLng: Languages.EN,
  debug: false,
  ns: ['common'],
  defaultNS: 'common',
  resources: {
    en, zh, zh_TW, ja, ar, ko, fr, id, es, ms, pt, he, vi
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;

import { Languages } from './language';

export const SUPPORT_EMAIL: Record<Languages, string> = {
  [Languages.AR]: 'support@acy.com',
  [Languages.EN]: 'support@acy.com',
  [Languages.ES]: 'support@acy.com',
  [Languages.FR]: 'support@acy.com',
  [Languages.HE]: 'support@acy.com',
  [Languages.ID]: 'support@acy.com',
  [Languages.JP]: 'support@acy.com',
  [Languages.KO]: 'support@acy.com',
  [Languages.MS]: 'support@acy.com',
  [Languages.PT]: 'support@acy.com',
  [Languages.VI]: 'support@acy.com',
  [Languages.ZH_CN]: 'support.cn@acy.com',
  [Languages.ZH_TW]: 'support.cn@acy.com'
};

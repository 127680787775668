import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from 'store/context/authContext';
import { Provider } from 'react-redux';
import { store } from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'i18n';

if (process.env.REACT_APP_NODE_ENV === 'dev'
  && process.env.REACT_APP_MOCK_SERVER_SWITCH === 'TRUE') {
  if (process.env.REACT_APP_ACCOUNT_STATUS === 'INCOMPLETE') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
    const { worker } = require('./mocks/browser');
    worker.start();
  }
  if (process.env.REACT_APP_ACCOUNT_STATUS === 'REJECT') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
    const { rejectAccountWorker } = require('./mocks/browser');
    rejectAccountWorker.start();
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <AuthContextProvider>
      {/* <React.StrictMode> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </React.StrictMode> */}
    </AuthContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

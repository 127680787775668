import axios from 'api/axios';
import { AxiosPromise } from 'axios';

export const uploadDocument = (
  accountID: string,
  documentCategory: string,
  file: File,
  targetId: number | undefined,
  documentId: number | string | undefined
): AxiosPromise => axios({
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  url: `/accounts/${accountID}/documents`,
  data: {
    target_id: targetId,
    document_category: documentCategory,
    file,
    targetId,
    document_id: documentId
  }
});

export const deleteDocument = (
  accountID: string,
  documentId? : number
): AxiosPromise => axios({
  method: 'DELETE',
  url: `/accounts/${accountID}/documents/${documentId}`
});

export const getFileList = (
  accountID: string
): AxiosPromise => axios({
  method: 'GET',
  url: `/accounts/${accountID}/documents`
});
